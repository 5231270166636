<template>
  <div>
    <div class="mt-3 mobile" :style="criandoSeguro ? 'position:fixed;' : ''">
      <div class="row">
        <div class="col-3 col-sm-2 box-line-miguel">
          <img src="/img/Untitled-1.svg" class="mt-4" height="65" />
        </div>
        <div class="miguelSuperior col-9 col-sm-10">
          <div v-if="step == 1">
            Olá, eu sou o Miguel :)
            <p />
            Você está perto de adicionar a cobertura de acessório ao seu seguro.
            <p />
          </div>
          <div v-if="step == 2">
            Após fazer o upgrade, a nova cobertura de acessório entrará em
              vigor na sua próxima apólice com a renovação em
              {{ formatacaoData.formatarDataLocal(dataVencimento) }}.
            Até lá você não será cobrado(a) por isso.
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2 box-line">
          <div class="passo-circle-completo" :class="{ filled: step >= 2 }">
            {{ step > 1 ? "✓" : "1" }}
          </div>
          <!-- <div><img src="/img/linha.svg" style="margin-left:11px" /></div> -->
          <hr class="line" />
        </div>
        <div class="col-10 mt-2 pb-4">
          <div class="texto-superio">Dados do acessório</div>
          <div v-show="step == 1 ? true : false">
            <div class="form-group"></div>
            <div>
              <div class="font-size-infor">
                Esses dados serão utilizados para cotar sua cobertura para
                acessório.
              </div>
              <div class="mt-3">
                <ValidationObserver v-slot="{ invalid }">
                  <form data-test-id="form-novo-cliente">
                    <div class="form-group ">
                      <label
                        >Descrição do acessório <small>(obrigatório)</small>
                      </label>
                      <ValidationProvider
                        name="descrição do acessório"
                        rules="required"
                        v-slot="{ errors, classes }"
                      >
                        <select
                          class="form-control"
                          v-model="nomeAcessorio"
                          :class="classes"
                          data-test-id="input-estado"
                        >
                          <option :value="undefined"
                            >Selecione o acessório...</option
                          >
                          <option value="Ciclocomputador/ GPS/ Garmin"
                            >Ciclocomputador/ GPS/ Garmin</option
                          >
                          <!-- <option value="Medidor de potência"
                              >Medidor de potência</option
                            >
                            <option value="Sensor de cadência"
                              >Sensor de cadência</option
                            >
                            <option value="Capacete">Capacete</option> -->
                        </select>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group ">
                      <label
                        >Marca do acessório <small>(obrigatório)</small>
                      </label>
                      <ValidationProvider
                        name="marca do acessório"
                        rules="required"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          v-model="marcaAcessorio"
                          :class="classes"
                          maxlength="100"
                          autocomplete="nope"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group ">
                      <label
                        >Valor do acessório <small>(obrigatório)</small>
                      </label>
                      <ValidationProvider
                        name="valor do acessório"
                        :rules="'required|valor_acessorio'"
                        v-slot="{ errors, classes }"
                      >
                        <money
                          v-model="valorAcessorio"
                          v-bind="{
                            decimal: ',',
                            thousands: '.',
                            prefix: 'R$ ',
                            suffix: '',
                            precision: 0,
                          }"
                          :class="['form-control', classes]"
                        ></money>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <b-button
                          class="btn-block"
                          variant="outline-success"
                          to="/cliente"
                        >
                          Voltar
                        </b-button>
                      </div>
                      <div class="col-6 text-right">
                        <b-button
                          class="btn-block"
                          variant="success"
                          :disabled="invalid"
                          @click.prevent="buscarPlanos()"
                        >
                          Continuar
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-2 box-line">
          <div
            :class="
              step >= 2 ? 'passo-circle-completo ' : 'passo-circle filled'
            "
          >
            2
          </div>
          <hr class="line2" />
        </div>
        <div>
          <div class="row">
            <div class="col-12 mt-3">
              <div v-show="step == 2 ? true : false">
                <div class="texto-superio">
                  Adiquirir cobertura de acessório
                </div>
                <div class="font-size-infor mb-3 text-justify"></div>
                <div class="mt-2">
                  <div class="mt-2">
                    <b-alert
                      class="mt-3 font-size-infor text-justify"
                      show
                      variant="info"
                    >
                <strong> Em caso de sinistro,
                              será obrigatório a apresentação da nota fiscal do
                              aparelho com a data anterior a contratação do
                              seguro.</strong> 
                    </b-alert>
                  </div>
                </div>
                <div class="row d-flex justify-content-center">
                  <div class="mb-3 box-shadow col-xl-4 col-lg-12">
                    <div
                      class="card card-body-plano border-success
                  "
                    >
                      <div class="card-body">
                        <h5>Cobertura para o acessório</h5>
                        <div class="idenizacao mb-1">
                          <strong>
                            Indenização:
                            {{ valorAcessorio }}</strong
                          >
                        </div>
                        <ul class="plano-detalhes">
                          <li>
                            {{ nomeAcessorio }}
                          </li>
                        </ul>
                      </div>
                      <div class="card-footer-desktop">
                        <div class="text-center text-muted">
                          <h2
                            class="card-title pricing-card-title"
                            style="color: #23ad21"
                          >
                            {{ formatacaoMoeda3(valorPlanoAcessorio) }}
                            <small class="text-muted">/ mês</small>
                          </h2>
                        </div>

                        <button
                          :disabled="criandoSeguro"
                          class="btn btn-block-desktop btn-success"
                          @click="criarNovoSeguro()"
                        >
                          Contratar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-5">
                  <div class="col-12">
                    <b-button
                      class="btn-block "
                      variant="outline-success"
                      @click.prevent="voltarPasso()"
                    >
                      Voltar
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading :criandoSeguro="criandoSeguro" v-if="criandoSeguro" />
    </div>
    <!-- ======================DeskTop===================== -->
    <div class="row desktop">
      <div class="col-3 retangulo-desktop">
        <div class="row">
          <div class="col-12 miguel-desktop">
            <img src="/img/Untitled-1.svg" class="img-miguel-desktop" />
          </div>
          <div class="col-12">
            <div class="bem-vindo-desktop" v-if="step == 1">
              Olá, eu sou o Miguel :)
              <p />
              Aqui você pode fazer o upgrade do seu seguro adicionando a cobertura para seus acessórios.
              <p />
            </div>
            <div class="bem-vindo-desktop" v-if="step == 2">
              Para contratar cobertura para acessório clique em contratar
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 layout-desktop">
        <div v-show="step == 1 ? true : false">
          <div>
            <ValidationObserver v-slot="{ invalid }">
              <form data-test-id="">
                <div
                  class="row mt-5 card card-body"
                  style="border-radius: 20px"
                >
                  <div class="col-12">
                    <div class="font-size-infor-desktop">
                      Você gostaria de adicionar cobertura para seus acessórios?
                    </div>

                    <div class="mt-3">
                      <div class="form-group">
                        <div class="row ">
                            <b-alert
                              class="mt-3 font-size-infor text-justify"
                              show
                              variant="info"
                            >
                          <strong>     Em caso de sinistro,
                              será obrigatório a apresentação da nota fiscal do
                              aparelho com a data anterior a contratação do
                              seguro.</strong> 
                            </b-alert>
                          </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-6">
                          <label
                            >Descrição do acessório <small>(obrigatório)</small>
                          </label>
                          <ValidationProvider
                            name="descrição do acessório"
                            rules="required"
                            v-slot="{ errors, classes }"
                          >
                            <select
                              class="form-control"
                              v-model="nomeAcessorio"
                              :class="classes"
                              data-test-id="input-estado"
                            >
                              <option :value="undefined"
                                >Selecione o acessório...</option
                              >
                              <option value="Ciclocomputador/ GPS/ Garmin"
                                >Ciclocomputador/ GPS/ Garmin</option
                              >
                              <!-- <option value="Medidor de potência"
                                >Medidor de potência</option
                              >
                              <option value="Sensor de cadência"
                                >Sensor de cadência</option
                              >
                              <option value="Capacete">Capacete</option> -->
                            </select>
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-6">
                          <label
                            >Marca do acessório <small>(obrigatório)</small>
                          </label>
                          <ValidationProvider
                            name="marca do acessório"
                            rules="required"
                            v-slot="{ errors, classes }"
                          >
                            <input
                              type="text"
                              class="form-control"
                              v-model="marcaAcessorio"
                              :class="classes"
                              maxlength="100"
                              autocomplete="nope"
                            />
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-6">
                          <label
                            >Valor do acessório <small>(obrigatório)</small>
                          </label>
                          <ValidationProvider
                            name="valor do acessório"
                            :rules="'required|valor_acessorio'"
                            v-slot="{ errors, classes }"
                          >
                            <money
                              v-model="valorAcessorio"
                              v-bind="{
                                decimal: ',',
                                thousands: '.',
                                prefix: 'R$ ',
                                suffix: '',
                                precision: 0,
                              }"
                              :class="['form-control', classes]"
                            ></money>
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <b-button
                      class="sucesso-botao-desktop"
                      variant="outline-success"
                    to="/cliente"
                    >
                      Voltar
                    </b-button>
                  </div>
                  <div class="col-6 text-right">
                    <b-button
                      class="sucesso-botao-desktop"
                      variant="success"
                      :disabled="invalid"
                      @click.prevent="buscarPlanos()"
                    >
                      Continuar
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div v-show="step == 2 ? true : false">
          <div class="row mt-5 card card-body" style="border-radius: 20px">
            <div class="col-12">
              <div class="font-size-infor-desktop mb-3">
                <b-alert
                  class="mt-3 font-size-infor text-justify"
                  show
                  variant="info"
                >
                  <strong
                    >Após fazer o upgrade, a nova cobertura de acessório entrará
                    em vigor na sua próxima apólice com a renovação em
                    {{
                      formatacaoData.formatarDataLocal(dataVencimento)
                    }}.</strong
                  >
                  Até lá você não será cobrado(a) por isso.</b-alert
                >
              </div>

              <div
                class="card card-body-plano border-success'
                  "
              >
                <div class="card-body">
                  <h5>Cobertura para o acessório</h5>
                  <div class="idenizacao mb-1">
                    <strong>
                      Indenização:
                    {{ formatacaoMoeda3(valorAcessorio) }}</strong
                    >
                  </div>
                  <ul class="plano-detalhes">
                    <li>
                      {{ nomeAcessorio }}
                    </li>
                  </ul>
                </div>
                <div class="card-footer-desktop">
                  <div class="text-center text-muted">
                    <h2
                      class="card-title pricing-card-title"
                      style="color: #23ad21"
                    >
                      {{ formatacaoMoeda3(valorPlanoAcessorio) }}
                      <small class="text-muted">/ mês</small>
                    </h2>
                  </div>

                  <button
                    :disabled="criandoSeguro"
                    class="btn btn-block-desktop btn-success"
                    @click="criarNovoSeguro()"
                  >
                    Contratar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <b-button
                class="sucesso-botao-desktop"
                variant="outline-success"
                @click.prevent="voltarPasso()"
              >
                Voltar
              </b-button>
            </div>
          </div>
        </div>
        <div>
          <Loading :criandoSeguro="criandoSeguro" v-if="criandoSeguro" />
        </div>
      </div>
    </div>
    <ModalInfor
      :showModalInfor="showModalInfor"
      :titulo="titulo"
      :mensagemHtml="mensagemHtml"
      @hide="hideModalInfor"
    />
  </div>
</template>

<script>
import auth from "../../auth";
import axios from "axios";
import Swal from "sweetalert2";
import captureError from "../../helpers/captureError";
import config from "../../config";
import formatacaoMoeda3 from "../../helpers/formatacaoMoeda3";
import Loading from "../../components/Usuario/Loading.vue";
import { Money } from "v-money";
import helper from "../../helpers/helper";
import formatacaoData from "../../helpers/formatacaoData";
export default {
  components: {
    Money,
    ModalInfor: () => import("../../components/Modal/ModalInfor.vue"),
    Loading,
  },
  data() {
    return {
      helper,
      config,
      formatacaoMoeda3,
      formatacaoData,
      auth,
      step: 1,

      criandoSeguro: false,

      //Cobertura acessório
      coberturaAcessorio: false,
      marcaAcessorio: undefined,
      nomeAcessorio: undefined,
      valorAcessorio: 0,
      valorPlanoAcessorio: undefined,
      valorBike: 0,

      //modal infor

      titulo: "",
      showModalInfor: false,
      mensagemHtml: "",
      coberturaCheck: false,
    };
  },
  created() {
    this.valorBike = this.$route.params.valorBike;
    this.idSeguro = this.$route.params.id;
    this.dataVencimento = this.$route.params.data;
  },
  watch: {
    marcaAcessorio: function(value) {
      this.marcaAcessorio = value.toUpperCase();
    },
    coberturaCheck: function(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.abrirModalInfor();
      }
    },
  },
  methods: {
    voltarPasso() {
      this.step--;
    },
    async criarNovoSeguro() {
      var result = await Swal.fire({
        title: "Atenção!",
        text:
          "Você está fazendo um upgrade no seu seguro e a partir da próxima renovação sua apólice terá a cobertura para seu acessório",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4B9C2E",
        confirmButtonText: "Ok, entendi",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#d33",
      });
      if (result?.isConfirmed == false || result?.dismiss == "cancel") {
        return;
      }
      try {
        this.criandoSeguro = true;

        var data = {
          idSeguro: this.idSeguro,
          valorAcessorio: this.valorAcessorio,
          valorPlanoAcessorio: this.valorPlanoAcessorio,
          NomeAcessorio: this.nomeAcessorio,
          marcaAcessorio: this.marcaAcessorio
        };

        await axios.post(
          `${config.API_URLV2}/seguro/AdquirirSeguroAcessorio`,
          data
        );

        this.criandoSeguro = false;
        Swal.fire(
          "Parabéns! ",
          `Você fez o upgrade do seu seguro e adicionou a cobertura para o acessório ${this.nomeAcessorio} `,
          "success"
        );
        this.$router.replace("/cliente");
      } catch (error) {
        return this.validacoes(error);
      }
    },
     validacoes(error) {
      this.criandoSeguro = false;
      captureError(error);
      if (error?.response?.data?.error) {
        this.$toast.error(error?.response?.data?.error, {
          duration: 8000,
          position: "top",
        });
      } else {
        Swal.fire({
          title: "Não foi possível assinar sua cobertura para seu acessório",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      return false;
    },
    async buscarPlanos() {
      try {
        this.criandoSeguro = true;
        var response = await axios.get(
          `${config.API_URLV2}/seguro/precosPlanoAcessorio?idSeguro=${this.idSeguro}&valorAcessorio=${this.valorAcessorio}`
        );
        this.valorPlanoAcessorio = response.data.valorAcessorio;
        this.criandoSeguro = false;
        this.step++;
        return true;
      } catch (error) {
        captureError(error);
        this.criandoSeguro = false;
        Swal.fire({
          title: "Não foi possível buscar o valor do plano do acessório",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });

        return false;
      }
    },
    abrirModalInfor() {
      this.titulo = "Informação importante";
      this.mensagemHtml = `<strong>Atenção:</strong>  Você está incluindo cobertura para um acessório. Em caso de
            sinistro, será obrigatório a apresentação da nota fiscal do aparelho
            com a data anterior a contratação do seguro. Você possui a nota
            fiscal?`;
      this.showModalInfor = true;
    },
    hideModalInfor() {
      this.showModalInfor = false;
      this.coberturaAcessorio = true;
    },
  },
};
</script>
<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: calc(1.5em + 1rem + 8px);
}
.vs--searchable .vs__dropdown-toggle {
  height: calc(1.5em + 1rem + 8px);
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #23ad21;
  background-color: #23ad21;
}
</style>
<style scoped>
.btn-planos-periodo {
  border-radius: 100px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
}
.toggle-planos {
  background-color: #e7e7e7;
  border-radius: 100px;
  padding: 3px;
  display: inline-flex;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}
.form-control {
  height: calc(1.5em + 1rem + 5px);
  font-size: 1.1rem;
}

.miguelSuperior {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 7px;
  width: 225px;
  right: 05px;
  color: white;
  background: #28a745;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 13px 13px 13px;
  font-size: 15px;
}
.line {
  background: #28a745;
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}
.line2 {
  background: #ffffff;
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}
.line3 {
  background: #c4c4c4;
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}

.box-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box-line-miguel {
  display: flex;
  flex-direction: column;
}

.passo-circle-completo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  background-color: #23ad21;

  border: 3px solid #23ad21;
  color: white;
  text-align: center;
}
.passo-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #c4c4c4;
  color: #23ad21;
  text-align: center;
}

.passo-circle.filled {
  background-color: #c4c4c4;
  color: white;
}
.texto-superio {
  color: #11481d;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.badge-success {
  color: #fff;
  text-align: left;
  background-color: #28a745;
  margin-top: -7px;
  float: right;
  margin-right: 25px;
  border-radius: 5px;
}
.font-size-infor {
  font-size: 12px;
  font-weight: 500;
}
.bloquei-pagina {
  position: fixed;
}
.btn-block {
  padding: 12px;
  border-radius: 10px;
}
.btn-planos {
  padding: 10px;
  border-start-end-radius: 7px;
  border-start-start-radius: 7px;
  display: block;
  width: 100%;
  margin-bottom: -1px;
}
.plano-detalhes {
  margin-left: -22px;
}
/*================== DeskTop =================================*/
.layout-desktop {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 20px;
}
.retangulo-desktop {
  background: linear-gradient(180deg, #303030 0%, rgba(0, 0, 0, 0) 100%),
    #28a745;
}
.textor-superio-desktop {
  color: #11481d;
  font-weight: 500;
  line-height: 0.2;
  font-size: 12px;
  text-align: center;
}
.row {
  margin: 0;
}

.badge-success-desktop {
  color: #fff;
  text-align: left;
  background-color: #28a745;
  margin-top: -7px;
  float: right;
  margin-right: 25px;
  border-radius: 5px;
}
.font-size-infor-desktop {
  font-size: 18px;
  margin-top: 5px;
  font-weight: 500;
  color: #11481d;
}
.font-size-infor-desktop-planos {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
  margin-left: 15px;
  color: #11481d;
}
.idenizacao {
  font-size: 13px;
}
.btn-block-desktop {
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: -1px;
}
.img-miguel-desktop {
  width: 100px;
}

.miguel-desktop {
  text-align: center;
  position: relative;
  margin-top: 98px;
}
.bem-vindo-desktop {
  padding: 21px;
  bottom: 38.92%;
  margin-top: 28px;
  background: #ffffff;
  border-radius: 24px 0px 24px 24px;
  font-size: 14px;
}
.bem-vindo-desktop-2 {
  padding: 21px;
  padding-bottom: 1.92%;
  margin-top: 30px;
  background: #ffffff;
  border-radius: 24px 0px 24px 24px;
  font-size: 14px;
}
.texto-desktop {
  font-size: 12px;
  line-height: 20px;
  /* or 120% */
  display: flex;
  align-items: center;

  color: #777777;
}

.sucesso-botao-desktop {
  width: 180px;
  border-radius: 10px;
  padding: 11px;
  margin-bottom: 30px;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #23ad21;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #23ad21;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
.card-body-plano-desktop {
  min-height: 394px;
  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border: 1px solid #a3a5a3;
  box-sizing: border-box;
  border-radius: 10px;
}
.card-body-plano-desktop-prata {
  min-height: 294px;
  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border: 1px solid #a3a5a3;
  box-sizing: border-box;
  border-radius: 10px;
}

.plano-recomendado-desktop {
  min-height: 447px;
}
.plano-recomendado-desktop-ouro {
  min-height: 379px;
}
.plano-nao-recomendado-desktop {
  margin-top: 20px;
}

.texto-card-desktop {
  font-size: 13px;
}
.card-footer-desktop {
  padding: 0rem 0em;
  background-color: rgb(255 255 255 / 3%);
  border-top: 1px solid rgb(255 255 255 / 13%);
}
.desktop {
  height: 100vh;
}
.mobile {
  display: none;
}
.texto-desconto-desktop {
  font-size: 12px;
  color: #28a745;
  line-height: 19px;
  font-weight: 700;
  background-color: #c4ffc3;
  border-radius: 100px;
  padding-left: 3px;
  padding-right: 7px;
}
.icon-desconto {
  vertical-align: text-top;
  width: 16px;
}
.titulo {
  display: inline-flex;
}
@media (max-width: 768px) {
  .mobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .desktop {
    display: none;
  }
}
@media (max-width: 1198px) {
  .texto-card-desktop {
    font-size: 20px;
  }
  .pricing-card-title-desktop {
    font-size: 35px;
  }
  .card-title-desktop {
    font-size: 27px;
  }
}
</style>
